<template>
  <div class="fixed top-0 z-50 w-screen">
    <div class="relative">
      <div class="flex h-1 overflow-hidden text-xs bg-dotyellow">
        <div
          class="flex flex-col justify-center text-center text-white shadow-none  bg-dotred whitespace-nowrap"
          data-0="width:0%;"
          data-end="width:100%;"
          style=""
        ></div>
      </div>
    </div>
  </div>
</template>