<template>
  <h3 class="mb-4 text-3xl font-bold">Some Clients <span class="text-xs">(alphabetical)</span></h3>
  <ul class="pl-5 list-disc">
    <li>
      Curious Cluster
      <span class="tag">Polpo Tech</span>
    </li>
    <li>Curious Games</li>
    <li>Concerts East</li>
    <li>Emirates</li>
    <li>Flamm Bodrum</li>
    <li>
      Garanti BBVA
      <span class="tag">&amp;club</span>
      <span class="tag">+Bonus Card</span>
      <span class="tag">Flexi</span>
      <span class="tag">shop&amp;miles</span>
    </li>
    <li>Groovypedia</li>
    <li>Istanbul Bilgi University</li>
    <li>Journey Café Cihangir</li>
    <li>
      Kopaş Kozmetik
      <span class="tag">Dalin</span>
      <span class="tag">Montibello</span>
      <span class="tag">Sesu</span>
      <span class="tag">XO</span>
    </li>
    <li>
      Nestlé
      <span class="tag">Nescafé</span>
    </li>
    <li>Peak Games</li>
    <li>Pixelplus</li>
    <li>Webbox</li>
  </ul>
</template>

<style scoped>
  .tag {
    @apply inline-block px-2 py-1 ml-2 text-xs text-white bg-dotred rounded shadow-md;
  }
</style>