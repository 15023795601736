<template>
  <div class="fixed right-3/4 top-56 md:right-1/3 md:top-1/3">
    <div class="relative">
      <div
        class="absolute top-0 rounded-full opacity-75 mix-blend-multiply filter blur-2xl animate-blob -left-4 w-72 h-72 bg-dotyellow md:opacity-100"
      ></div>
      <div
        class="absolute top-0 rounded-full opacity-75 mix-blend-multiply filter blur-2xl animate-blob ani-delay-2000 -left-48 w-72 h-72 bg-dotred md:opacity-100"
      ></div>
      <div
        class="absolute rounded-full opacity-75 top-10 mix-blend-multiply filter blur-2xl animate-blob ani-delay-4000 left-32 w-72 h-72 bg-dotgreen md:opacity-100"
      ></div>
    </div>
  </div>
</template>