<template>
  <h3 class="mb-4 text-3xl font-bold">Design and Coding</h3>
  <ul class="pl-5 list-disc">
    <li>
      Design
      <ul class="list-disc">
        <li class="ml-5">
          Adobe
          <span class="tag">Photoshop</span>
          <span class="tag">Illustrator</span>
          <span class="tag">XD</span>
        </li>
      </ul>
    </li>
    <li>
      Front-End
      <ul class="list-disc">
        <li class="ml-5">
          Standart Front-end Languages
          <span class="tag">HTML</span>
          <span class="tag">SCSS</span>
          <span class="tag">JS</span>
        </li>
        <li class="ml-5">
          Libraries &amp; Frameworks
          <span class="tag">vue.js</span>
          <span class="tag">nuxt.js</span>
          <span class="tag">electron.js</span>
          <span class="tag">next.js</span>
        </li>
      </ul>
    </li>
    <li>
      Back-End
      <ul class="list-disc">
        <li class="ml-5">
          Python
          <span class="tag">Django</span>
        </li>
      </ul>
    </li>
  </ul>
</template>

<style scoped>
  .tag {
    @apply inline-block px-2 py-1 ml-2 text-xs text-black bg-dotyellow rounded shadow-md;
  }
</style>
